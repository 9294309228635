import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {useAuth} from '../auth'
import {useCalendarData} from './core/CalendarDataProvider'

export const WelcomePage: FC = () => {
  const {onlineBookingSettings} = useCalendarData()
  const {settings} = useAuth()
  const formattedAmount =
    Intl.NumberFormat('en-US').format(
      parseInt(
        onlineBookingSettings?.validate_amount ? onlineBookingSettings?.validate_amount : '0'
      )
    ) + '₮'

  return (
    <div className='d-flex flex-column flex-root welcome'>
      <div className=' welcome-top'>
        <div
          className='d-flex flex-column flex-md-row flex-lg-row'
          style={{
            maxWidth: 1200,
            marginInline: 'auto',
            width: 'clamp(200px, 100vw - 20px, 1200px)',
          }}
        >
          <div
            className='d-lg-flex flex-lg-row-fluid bgi-size-cover bgi-no-repeat welcome-img'
            style={{
              backgroundImage: `url("${onlineBookingSettings.image_url}")`,
            }}
          ></div>

          <div
            className='d-flex flex-column w-lg-80 p-lg-13 px-18 py-8 mt-10 mb-20'
            style={{justifyContent: 'center'}}
          >
            <div className='fs-2x fw-bolder text-gray-800'>{settings?.company_name}</div>
            <div className='fs-3 mt-2 mb-xl-18 mb-5'>
              <i className='fa fa-solid fa-location-dot text-primary fs-3'></i> {settings?.address}
            </div>
            {onlineBookingSettings?.choose_qpay && (
              <div className='text-success fs-6 w-400px mb-4'>
                <span>
                  Цаг захиалгыг баталгаажуулах зорилгоор урьдчилгаа төлбөр авахыг анхаарна уу!{' '}
                </span>
                <span className='fw-bold'>{formattedAmount}</span>
              </div>
            )}
            <Link
              to='/booking/service'
              className='welcome-booking-btn btn btn-primary mb-xl-0 mb-12'
            >
              Цаг авах
            </Link>
          </div>
        </div>

        {/* ============= WAVE ============ */}
        <div className='wave'>
          <div className='box'></div>
          <div className='box'></div>
        </div>
      </div>

      <div className='welcome-about'>
        <div
          className='d-flex flex-column flex-md-row flex-lg-row'
          style={{
            maxWidth: 1200,
            marginInline: 'auto',
            width: 'clamp(200px, 100vw - 20px, 1200px)',
          }}
        >
          <div className='d-flex flex-lg-row py-10 py-md-20 px-xl-20'>
            <div className='w-lg-600px w-100 px-15'>
              <div className='d-inline-block'>
                <div className='welcome-about-us fw-bolder text-gray-800'>Бидний тухай</div>
                <div className='separator mx-1 mb-4 mt-1 border-primary '></div>
              </div>
              <div className='welcome-about-us-content'>{onlineBookingSettings.about}</div>

              <div className='fs-4 mt-10 text-gray-800'>
                <i className='fa fa-solid fa-clock text-primary fs-4'></i>
                <span className='fw-bold'>{' Цагийн хуваарь'}</span>

                <div className='welcome-about-us-content'>
                  Ажлын өдрүүд: {settings?.start_time} - {settings?.end_time} <br />
                  {/* Бямба, Ням: амарна */}
                </div>
              </div>

              <div className='fs-4 mt-5 text-gray-800'>
                <div className='welcome-about-us-content text-primary fs-5'>
                  {settings?.email && (
                    <div className='pb-1'>
                      <i className='fa fa-solid fa-envelope fs-5 pe-2 text-primary'></i>
                      <a href={'mailto:' + settings.email} className='text-primary'>
                        {settings.email}
                      </a>
                    </div>
                  )}

                  {settings?.phone && (
                    <div className='pb-1'>
                      <i className='fa fa-solid fa-phone fs-5 pe-2 text-primary'></i>
                      <span>{settings.phone}</span>
                    </div>
                  )}
                  <div className='pb-1'>
                    <i className='fa fa-solid fa-globe fs-5 pe-2 text-primary'></i>
                    <a href='http://xolbooc.com' rel='nofollow' className='text-primary'>
                      {' '}
                      {' Холбоос'}
                    </a>
                  </div>

                  {(settings?.fb_url || settings?.insta_url) && (
                    <div className='d-flex fb-1'>
                      <i className='fa fa-solid fa-share-alt fs-4 pe-2 text-primary pt-1'></i>
                      <div>
                        {settings?.fb_url && (
                          <Link
                            title='Go to facebook account. Open in new window'
                            to={settings.fb_url}
                            target='_blank'
                            className='fb'
                          >
                            <KTSVG
                              path='/media/icons/duotune/social/soc004.svg'
                              className='svg-icon-2 svg-icon-primary'
                            />
                          </Link>
                        )}
                        {settings?.insta_url && (
                          <Link
                            title='Go to instagram account. Open in new window'
                            to='https://www.facebook.com/profile.php?id=100087372374741'
                            target='_blank'
                            className='ig'
                          >
                            <KTSVG
                              path='/media/icons/duotune/social/soc005.svg'
                              className='svg-icon-2 svg-icon-primary'
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className='bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat welcome-company-img'
            style={{
              backgroundImage: `url("${'/media/mainImg.webp'}")`,
            }}
          ></div>
        </div>
      </div>
      {/* =================================================== */}
    </div>
  )
}
